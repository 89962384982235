@tailwind base;
@tailwind components;
@tailwind utilities;

/* *,
*:before,
*:after {
   box-sizing: border-box;
   padding: 0;
   margin: 0;
} */

body {
   overflow-x: hidden;
}

:root {
   --primary: #5156b8;
   --secondary: #d478d1;
   --color-dark: #2f2f2f;
   --color-light: #e2e2e2;
}

.primary {
   color: var(--primary);
}

.secondary {
   color: var(--secondary);
}

* {
   scrollbar-width: none;
   scrollbar-color: #5156b8 !important;
}

*::-webkit-scrollbar {
   width: 5px;
}

*::-webkit-scrollbar-track {
   background: transparent !important;
}

*::-webkit-scrollbar-thumb {
   background: linear-gradient(#5156b8, #d478d1) !important;
   border-radius: 20px;
   border: 0px solid #5156b8 !important;
}
